export const root = "https://images.dentalstores.in";
export const path = "https://images.dentalstores.in/";
export const dsinPath = "https://www.dentalstores.in";
// export const root = "http://127.0.0.1:8000";
// export const path = "http://127.0.0.1:8000/";

export const Algolia = {
    ApplicationID: "CJTWFOHYGN",
    // AdminAPIKey: "91c4273bb23ea1fa59fd3964ffa5c8ab"
    AdminAPIKey: "14d79586b8ccc70c067aabf4464eabcb"
}

export const COLORS = {
     firstcolor: 'hsl(210, 100%, 14%)',
     firstColorAlt: 'hsl(210, 93%, 7%)',
     firstColorLight: 'hsl(210, 93%, 27%)',
     firstColorLighten: 'hsl(210, 100%, 97%)',
     secondColor: 'hsl(10, 84%, 47%)',
     bodyColor: 'hsl(228, 14%, 93%)',
     titleColor: 'hsl(210, 100%, 14%)',
     textColor: 'hsl(215, 19%, 35%)',
     containerColor: 'hsl(0, 0%, 100%)',
     White: 'hsl(0, 0%, 100%)'
}

export const FAMILY = {
    LIGHT: 300,
    NORMAL: 400,
    MEDIUM: 500,
    SEMIBOLD: 600,
    BOLD: 700
}