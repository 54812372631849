import { createAction } from '@reduxjs/toolkit';

export const AddToCart = createAction('ADD_TO_CART', function prepare(product, qty) {
  return {
    payload: { product, qty },
  }
});

export const IncQty = createAction('INCREMENT', function prepare(id) {
  return {
    payload: id,
  }
});

export const DecQty = createAction('DECREMENT', function prepare(id) {
  return {
    payload: id,
  }
});

export const RemovePro = createAction('REMOVE', function prepare(id) {
  return {
    payload: id,
  }
});

export const Delivery = createAction('Delivery', function prepare(dc) {
  return {
    payload: dc,
  }
});

export const CouponAc = createAction('Coupon', function prepare(value) {
  return {
    payload: value,
  }
});

export const CODCHK = createAction('CODCHK', function prepare(value) {
  return {
    payload: value,
  }
});

export const GrandTotal = createAction('GrandTotal', function prepare(total) {
  return {
    payload: total,
  }
});

export const SAddress = createAction('Shipping_Address', function prepare(value) {
  return {
    payload: value,
  }
});

// Action to clear the cart
export const ClearCart = createAction('CLEAR_CART');